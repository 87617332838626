import "./navbar.css";
import React, { useState, useRef } from 'react';
// import { Link as ScrollLink, animateScroll } from 'react-scroll';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import companyLogo from '../../Assets/Images/primary-logo-without-descriptor.png'

export default function Navbar() {
    const [nav, setNav] = useState(false);

    const links = [
        {
            id: 1,
            link: "/",
            title: 'Home'
        },
        {
            id: 2,
            link: "/vip-benefits",
            title: 'VIP Benefits'
        },
        {
            id: 3,
            link: "/facilities",
            title: 'Facilities'
        },
        {
            id: 4,
            link: 'https://uat-patient.onehealthaccess.com/',
            title: "Book Now"
        },
        {
            id: 1,
            link: "/?scrollTo=about-us",
            title: 'About'
        },
        {
            id: 2,
            link: "/feedback",
            title: 'Feedback'
        },
        {
            id: 3,
            link: "/?scrollTo=contact",
            title: 'Contact Us'
        },
    ];

    const link = [
        {
            id: 1,
            to: "/?scrollTo=about-us",
            title: 'ABOUT'
        },
        {
            id: 2,
            to: "/feedback",
            title: 'FEEDBACK'
        },
        {
            id: 3,
            to: "/?scrollTo=contact",
            title: 'CONTACT US'
        },
    ];

    return (
        <div className='flex flex-col'>
            {/* <BookAppointmentModal isOpen={isModalOpen} onClose={closeModal} modalRef={modalRef} id="modal" /> */}
            <div className="flex justify-between items-center w-full px-4 h-14 py-1 text-white bg-paleOak fixed top-0 z-10 md:px-16">
                <div className="hidden items-center md:space-x-4 md:items-center justify-center lg:flex">
                    <div className="pb-1 md:mr-4 space-x-2">
                        <a href="#" className="text-earthyGreen"><FacebookIcon fontSize="small" /></a>
                        <a href="#" className="text-earthyGreen"><TwitterIcon fontSize="small" /></a>
                        <a href="#" className="text-earthyGreen"><InstagramIcon fontSize="small" /></a>
                    </div>
                    <div className="space-x-6">
                    {link.map(({ id, to, title }) => (
                        <a key={id} href={to} className="nav-link">
                            {title}
                        </a>
                    ))}
                    </div>
                    {/* {isHomePage ? (
                       
                    ) : (
                        <div className="pr-40">
                            <p></p>
                        </div>
                    )} */}
                </div>
                <div className="flex items-center">
                    <img className="w-52" src={companyLogo} alt="Company Brand" />
                </div>
                <div className="hidden space-x-6 md:flex">
                    <a href="/" className="nav-link">HOME</a>
                    {/* <ScrollLink to="modal" smooth={true} duration={500} className="nav-link" onClick={openModal}>BOOK</ScrollLink> */}
                    <a href="/vip-benefits" className="nav-link">VIP BENEFITS</a>
                    <a href="/facilities" className="nav-link">FACILITIES</a>
                    <a href="https://patient.onehealthaccess.com/" className="nav-link">BOOK NOW</a>
                </div>

                <div
                    onClick={() => setNav((prevNav) => !prevNav)}
                    className="cursor-pointer mr-4 z-10 text-gray-500 md:hidden"
                >
                    {nav ? <CloseIcon fontSize='small' className="text-earthyGreen" /> : <MenuIcon fontSize='small' className="text-earthyGreen" />}
                </div>
                {nav && (
                    <ul className="flex flex-col bg-mutedGreen absolute top-[3.5rem] left-0 w-full text-white py-2">
                        {links.map(({ id, link, title }) => (
                            <li
                                key={id}
                                className="px-4 py-3">
                                <a onClick={() => setNav(!nav)} href={link}>
                                    {title}
                                </a>
                            </li>
                        ))}
                    </ul>
                )}
            </div>

        </div>
    );
};