import './carousel.css'
import React, { useState, useRef } from 'react';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

//components
import WellnessPackagesItem from './WellnessPackagesItem';

//assets
import KidsWellness from '../../Assets/Images/carousel/kids-wellness.jpg'
import YoungAdult from '../../Assets/Images/carousel/young-adult.jpg'
import WomensPrime from '../../Assets/Images/carousel/womens-prime.jpg'
import OptimalMan from '../../Assets/Images/carousel/optimal-man.jpg'
import WorkLifeBalance from '../../Assets/Images/carousel/work-life-balance.jpg'
import PreEmployment from '../../Assets/Images/carousel/pre-employment.jpg'
import LifeStartsAt40 from '../../Assets/Images/carousel/life-starts-at-40.jpg'

function FacilitiesCarousel() {
    const [display, setDisplay] = useState("");
    const displayRef = useRef(null);

    const settings = {
        mobileFirst: true,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        ]
    };

    const data = [
        {
            title: "VIP lounge",
            img: KidsWellness,
            alt: "vip lounge",
            key: 1
        },
        {
            title: "Patient Lounge",
            img: YoungAdult,
            alt: "patient lounge",
            key: 2
        },
        {
            title: "Medical Laboratory",
            img: LifeStartsAt40,
            alt: "medical lab",
            key: 3
        },
        {
            title: "Dental Diagnostic ",
            img: PreEmployment,
            alt: "dental diagnostic",
            key: 4
        },
        {
            title: "Consultation Rooms",
            img: WorkLifeBalance,
            alt: "consultation room",
            key: 5
        },
    ]

    const handleClick = (key) => {
        setDisplay(key);
        displayRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div>
            <div className='mx-16 pt-10 pb-40'>
                <Slider {...settings} className=''>
                    {data.map((d) => (
                        <div key={d.key} className='w-full h-96 relative' onClick={() => handleClick(d.key)}>
                            <div>
                                <img src={d.img} alt={d.alt} className='object-cover w-full h-96' />
                            </div>
                            <div className='w-full h-full absolute top-0 opacity-0 hover:opacity-100'>
                                <div className='overlay'>
                                </div>
                                <div className='absolute top-0 w-full h-full text-white'>
                                    <div className='w-full h-full flex items-center'>
                                        <p className='text-lg mt-80 ml-5 font-bold tracking-widest'>{d.title}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default FacilitiesCarousel
