import './App.css';



import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';

// Pages
import Home from './Pages/Home/Home';
import VipPerks from './Pages/VipPerks/VipPerks';
import Amenities from './Pages/Facilities/Facilities';
import Feedback from './Pages/Home/Feedback'

function App() {
  return (
    <div className='overflow-hidden'>
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/vip-benefits' element={<VipPerks />} />
        <Route path='/facilities' element={<Amenities />} />
        <Route path='/feedback' element={<Feedback />} />
      </Routes>
      <Footer />
    </Router>
  </div>
  );
}

export default App;
