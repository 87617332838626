import React from 'react';
import displayImage from '../../Assets/Images/empty-room.jpg';

// Define the AboutUs component
const AboutUs = () => {
    // Event handler for button click
    const scrollToWellnessPackages = () => {
        // Scroll to the WellnessPackages section
        const element = document.getElementById('wellness-packages');
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    // Render the component
    return (
        <div className='text-earthyGreen'>
            <div className="flex min-h-full flex-col py-10 lg:px-8 bg-calmGray">
                <div className='container mt-10 mb-10 flex flex-col mx-auto px-5 max-w-screen-xl lg:flex-row lg:p-12'>
                    <div className='w-full text-justify lg:pr-16'>
                        <h1 className='text-4xl mt-11 mb-3 font-secondary font-bold'>About Us</h1>
                        <p className='mb-5'>
                            <span className='font-bold'>One Health Access</span> is a premium medical institution, aimed to create a holistic wellness experience for you.
                            We work together with patients, physicians, and partners to make health management accessible for you.
                            We offer a multitude of medical services ranging from laboratory to consultancy-based services to provide you with a wellness plan,
                            curated and maximized for you and your lifestyle. We envision to bridge the gap between healthcare and genuine wellness experience that keeps
                            you invested in your well-being.
                            <br /><br />
                            We are currently located on the second floor of Banilad Town Center, Banilad, Cebu City, 6000 Philippines.
                        </p>
                        {/* Button to scroll to WellnessPackages */}
                        <button className=' bg-earthyGreen  mb-10 text-white font-bold text-sm py-2 px-7 rounded-lg lg:mb-3' onClick={scrollToWellnessPackages}>
                            Get Started Now
                        </button>
                    </div>
                    <div className='w-full h-auto flex items-center'>
                        <img src={displayImage} className='rounded-md' alt="empty-room-without-furniture-include-plants-pool-concrete-wall-minimal-design-mock-up-space-3d" />
                    </div>
                </div>
            </div>
        </div>
    );
};

// Export the component
export default AboutUs;
