import './carousel.css'
import React, { useState, useRef } from 'react';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

//components
import WellnessPackagesItem from './WellnessPackagesItem';

//assets
import KidsWellness from '../../Assets/Images/carousel/kids-wellness.jpg'
import YoungAdult from '../../Assets/Images/carousel/young-adult.jpg'
import WomensPrime from '../../Assets/Images/carousel/womens-prime.jpg'
import OptimalMan from '../../Assets/Images/carousel/optimal-man.jpg'
import WorkLifeBalance from '../../Assets/Images/carousel/work-life-balance.jpg'
import PreEmployment from '../../Assets/Images/carousel/pre-employment.jpg'
import LifeStartsAt40 from '../../Assets/Images/carousel/life-starts-at-40.jpg'

function Carousel() {
    const [display, setDisplay] = useState("");
    const displayRef = useRef(null);

    const settings = {
        mobileFirst: true,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        ]
    };

    const data = [
        {
            title: "Kid's Wellness",
            subTitle: "(17yrs old & below)",
            text: "Every child deserves a healthy start in life. Invest in your children's health with out specialized wellness package for them. This package is inclusive of health basics like urinalysis and stool exam.",
            img: KidsWellness,
            alt: "kid's wellness",
            key: "kidsWellness"
        },
        {
            title: "Young Adult",
            subTitle: "(18-25 yrs.old)",
            text: "It's never too early to prioritize your health. Our comprehensive young adult wellness package is suited for the best interests of your children entreing their late teens and early 20s. This package is inclusive of several STI and STD tests paired with a consultation with our very own specialists.",
            img: YoungAdult,
            alt: "young adult",
            key: "youngAdult"
        },
        {
            title: "Women's Prime",
            subTitle: "(26-39 yrs. old)",
            text: "Pursue your goals and lead fulfilling lives as a powerful and healthy woman in her prime. This package is inclusive of specialized obstetrical and gynecological checks-ups, and screening for breast cancers.",
            img: WomensPrime,
            alt: "women's prime",
            key: "womensPrime"
        },
        {
            title: "Optimal Man",
            subTitle: "(26-39 yrs. old)",
            text: "For every man who's always on the move. This covers several health imperatives like prostate exams. ECGs, and even a comprehensive consulatation with our specialists; for an optimized lifestyle every man desires.",
            img: OptimalMan,
            alt: "optimal man",
            key: "optimalMan"
        },
        {
            title: "Work and Life Balance",
            subTitle: "(Lifestyle Check-up)",
            text: "Take time to recharge and take care of yourself. Maintain your work-life balance with our specialized lifestyle check-up to keep your body and mind in check as you charge onward. This package is inclusive of specialized psychiatric care and different body wellness tests.",
            img: WorkLifeBalance,
            alt: "work and life balance",
            key: "workLifeBalance"
        },
        {
            title: "Pre-employment",
            text: "Applying for a job? Here's a pre-employment wellness package for you! This package includes a complete physical body assessment, CBC, stool analysis, and even a chest x-ray.",
            img: PreEmployment,
            alt: "pre-employment",
            key: "preEmployment"
        },
        {
            title: "Life Starts at 40",
            text: "Getting old means making wiser choices. Invest in your health so you may reap the rewards of a better quality of life. This wellness package offers you the most comprehensive body assessment, from cancer markers and liver tests to thyroid panels and consultation with our very own medical specialist!",
            img: LifeStartsAt40,
            alt: "life starts at 40",
            key: "lifeStartsAt40"
        },
    ]

    const handleClick = (key) => {
        setDisplay(key);
        displayRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    
    return (
        <div>
            <div className='mx-16 pt-10 pb-40'>
                <Slider {...settings} className=''>
                    {data.map((d) => (
                        <div key={d.key} className='w-full h-96 relative' onClick={() => handleClick(d.key)}>
                            <div>
                                <img src={d.img} alt={d.alt} className='object-cover w-full h-96' />
                            </div>
                            <div className='w-full h-full absolute top-0 opacity-0 hover:opacity-100'>
                                <div className='overlay'>
                                </div>
                                <div className='absolute top-0 w-full h-full text-white'>
                                    <div className='w-full h-full flex items-center'>
                                        <p className='text-lg mt-80 ml-5 font-bold tracking-widest'>{d.title}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <div>
                {display && (
                    <div ref={displayRef}>
                        <WellnessPackagesItem display={display} data={data} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default Carousel
