import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { createFeedback } from '../../API/ApiCalls';
import { HashLoader } from 'react-spinners';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from 'react-google-recaptcha';

//6LejNoEpAAAAAOpDSVQxgiwdKvMK5_Q_ANZJeRmt

import EmailRoundedIcon from '@mui/icons-material/EmailRounded';

import './home.css'

export default function Feedback() {
    const [feedbackDetails, setFeedbackDetails] = useState({
        email: '',
        feedback: '',
    });
    const [captVal, setCaptVal] = useState(null)
    const [isLoading, setIsLoading] = useState(false);

    const handleFeedbackChange = (e) => {
        const { name, value } = e.target;
        setFeedbackDetails((prevDetails) => ({
          ...prevDetails,
          [name]: value
        }));
    };
    async function handleFeedbackSubmit() {
        setIsLoading(true)
        const response = await createFeedback(feedbackDetails);
        if(response.data){
            toast.success(response.data.message);
            setFeedbackDetails({
                email: '',
                feedback: '',
            })
        } else {
           response.error.data.messages.error_messages?.map((test) => (
            toast.error(test)
           ))
        }
        setIsLoading(false)
    }
    return (
        <div className="lg:h-screen h-full relative w-screen mx-auto flex items-center bg-holisticGreen">
            <div className='mx-auto flex-col flex gap-5 w-[40rem] mt-32 mb-20'>
                <div className="text-lg text-white flex justify-center items-center text-center">
                    <p>
                        Your feedback shapes our improvement journey. At <span className='font-bold'> One Health Access </span>, your opinion matters. Help us enhance our services by sharing your thoughts. Thank you for contributing to our growth!
                    </p>
                </div>
                <div className='bg-white p-5 flex flex-col gap-2 mx-6 lg:mx-0 rounded-md border border-calmGray'>
                    <h2 className="text-start text-earthyGreen mb-2 text-2xl font-semibold">Share your feedback</h2>
                    <form className="flex flex-col gap-3 w-full items-center">
                        <div className="focus:border-holisticGreen border-gray-300 rounded-md border relative w-full">
                            <input
                                id="email"
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={feedbackDetails.email}
                                onChange={(e) => handleFeedbackChange(e)}
                                autoComplete="off"
                                className="input"
                            />
                            <div className='absolute top-1.5 left-2'>
                                <EmailRoundedIcon className='text-holisticGreen' />
                            </div>
                        </div>
                        <div className="focus:border-holisticGreen h-32 border-gray-300 rounded-md border relative w-full">
                            <textarea
                                id="feedback"
                                name="feedback"
                                value={feedbackDetails.feedback}
                                placeholder="Input your feedback here..."
                                onChange={(e) => handleFeedbackChange(e)}
                                autoComplete="off"
                                className="ring-white rounded-md border-0  mb-1 text-gray-900 w-full h-full placeholder:text-gray-400 focus:border focus:border-holisticGreen py-2 px-3"
                            />
                        </div>
                        <ReCAPTCHA
                            sitekey='6LeNy6opAAAAAKxM44x3X0hZslYpPYX-jrYidZBT'
                            onChange={(val) => setCaptVal(val)} 
                        />
                        {
                            isLoading ? (
                                <button
                                    type="button"
                                    disabled
                                    className="flex w-48 justify-center rounded-md bg-holisticGreen px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-holisticGreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-holisticGreen">
                                        <HashLoader
                                            color="white"
                                            size={20}
                                        />
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    onClick={handleFeedbackSubmit}
                                    className="text-white bg-holisticGreen px-16 py-2 rounded-md mt-5">
                                    Submit
                                </button>
                            )
                        }
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
