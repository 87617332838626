import React from 'react'
import { Helmet } from 'react-helmet';

import Benifits from '../../Assets/Images/cozy-living-room.jpg'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';

export default function VipPerks() {

    const data = ["Consultation with Family Medicine","Complete Blood Count (CBC)", "Urinalysis (UA)", "Chest X-Ray (CXR)", "Lipid Profile", "10% LESS ON ALL SERVICES (MED/DENTAL/DIAGNOSTIC)", "Free Breakfast for Diagnostics with Fasting", "Priority Queue Upon Services",
        "Use of VIP Lounge", "Bi-annual Health Reminders"]
    return (
        <div className='pt-14 h-full lg:h-screen'>
            <Helmet>
                <title>VIP Benefits</title>
            </Helmet>
            <div className='text-earthyGreen h-full flex flex-col lg:flex-row items-center max-w-screen-2xl px-8 mx-auto'>
                <div className="w-[45rem] h-[29rem] p-9">
                    <img src={Benifits} alt="..." className='object-cover w-full h-full rounded-md'/>
                </div>
                <div className='flex flex-col justify-center w-full p-11'>
                    <h1 className='font-primary text-3xl pb-3 font-bold'>VIP Benefits</h1>
                    <div>
                        Invest in a healthcare package that puts you in prevention mode. The One Health Access VIP Card gives you 
                        <br/>bi-annual priority to the following benefits:
                    </div>
                    <ul className="flex flex-col gap-2 my-5 ms-5">
                        {data.map((item, index) => (
                            // <li key={index}> <CheckBoxOutlineBlankOutlinedIcon style={{ fontSize: '15px' }} /> {item}</li>
                            <li key={index} className='ms-5'> & {item}</li>
                        ))}
                    </ul>
                    <div className='mb-2'>
                        Our Wellness Concierge will remind you via SMS and email to make your appointment.                    
                    </div>
                </div>
            </div>
        </div>
    )
}
