import axios from "axios";

let Qrfileconfig = {
  headers: {
    "api-key": process.env.REACT_APP_API_KEY_QR,
    "Content-Type": "multipart/form-data",
  },
};

let qrHeaders = {
  "api-key": process.env.REACT_APP_API_KEY_QR,
  "Content-Type": "application/json",
};


export const getAPICallGoogle = (url) => {
  return axios.get(url);
};

export const postQrAPICallwithFile = (url, data) => {
  return axios.post(url, data, Qrfileconfig );
};

export const getQrWithParamsAPICall = (url, data) => {
  return axios.get(url, { headers: qrHeaders, params: data });
};
