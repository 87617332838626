import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

//assets
import LandingImage from '../../Assets/Images/empty-area.jpg'
import earthyGreenFadedLine from '../../Assets/Images/earthy-green-faded-line.png'

//pages
import AboutUs from './AboutUs';
import WellnessPackages from './WellnessPackages'
import ContactUs from './ContactUs';

export default function Home() {
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const scrollTo = params.get("scrollTo");
        if (scrollTo) {
            const element = document.getElementById(scrollTo);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location.search]);
    return (
        <div>
            <div className='h-full'>
                <div className='w-full flex justify-center'>
                    <img className="w-screen mx-auto h-[45rem] object-cover" src={LandingImage} alt="" />
                </div>
                <div className='font-secondary w-full mt-32 mb-9 lg:mb-16 flex flex-col text-center justify-center items-center font-bold text-3xl text-earthyGreen'>
                    <h1 className='w-full md:w-[26rem]'>Redefining Healthcare for Every Cebuano.</h1>
                    <div className='w-full flex justify-center'>
                        <img src={earthyGreenFadedLine} className='w-96' alt="" />
                    </div>
                </div>
                <div id="about-us">
                    <AboutUs />
                </div>
                <div id="wellness-packages">
                    <WellnessPackages />
                </div>
                <div id="contact">
                    <ContactUs/>
                </div>
            </div>
        </div>
    )
}
