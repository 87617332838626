//icons
import CopyrightIcon from '@mui/icons-material/Copyright';

//images
import CompanyLogo from "../../Assets/Images/oha-primary-logo.png"


export default function Footer() {
    const link = [
        {
            id: 1,
            to: "/?scrollTo=about-us", // Add query parameter
            title: 'ABOUT'
        },
        {
            id: 2,
            to: "/feedback", // Add query parameter
            title: 'FEEDBACK'
        },
        {
            id: 2,
            to: "/?scrollTo=contact", // Add query parameter
            title: 'CONTACT US'
        }
    ];
    return (
        <nav className="bg-paleOak w-screen p-6 flex justify-between text-earthyGreen flex-col-reverse sm:flex-row">
            <div className='w-full grid text-center justify-items-center'>
                <img className="w-52" src={CompanyLogo} alt="Company Brand" />
                <p className='text-sm mb-3 lg:mb-14 font-bold'>Redefine your healthcare with us!</p>
                <p className='text-xs'>Copyright <CopyrightIcon fontSize="tiny" /> 2023 OHA. All rights reserved</p>
            </div>
            <div className='w-full text-center text-sm order-1 lg:order-2'>
                <h3 className='font-bold mb-3 text-lg'>About OHA</h3>
                <ul className="space-y-2">                    
                {link.map(({ id, to, title }) => (
                        <li>
                            <a key={id} href={to}>
                                {title}
                            </a>
                        </li>
                    ))}
                    <li><a href="/vip-benefits">VIP BENEFITS</a></li>
                    <li><a href="/facilities">FACILITIES</a></li>
                    <li><a href="https://uat-patient.onehealthaccess.com/">BOOK NOW</a></li>
                </ul>
            </div>
            <div className='w-full text-center text-sm my-10 sm:my-0'>
                <h3 className='font-bold mb-3 text-lg'>Others</h3>
                <ul className="space-y-2">
                    <li><a href="#">PRIVACY POLICY</a></li>
                    <li><a href="#">TERMS AND CONDITIONS</a></li>
                    <li><a href="#">HELP CENTER</a></li>
                </ul>
            </div>
        </nav>
    )
}
