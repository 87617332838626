import './facilities.css'
import { Helmet } from 'react-helmet';

//components
import FacilitiesCarousel from '../../Components/Carousel/FacilitiesCarousel';

//icons
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import PersonIcon from '@mui/icons-material/Person';
import landingImage from '../../Assets/Images/armchair-sofa.jpg'
import earthyGreenFadedLine from '../../Assets/Images/earthy-green-faded-line.png'

export const metadata = {
    title: 'Amenities'
}

export default function Amenities() {
    const testimonies = [
        {
            name: "Johnny Bravo",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        },
        {
            name: "JO",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        },
        {
            name: "John Doe",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        }

    ]

    return (
        <div>
            <Helmet>
                <title>Facilities</title>
            </Helmet>
            <div className="relative w-screen mx-auto">
                <div className="relative h-full flex items-center justify-center">
                    <img className="h-[45rem] object-cover" src={landingImage} alt="armchair-sofa-decoration-japanese-modern-room-interior-wabisabi-style3d-rendering" />
                    <div className="absolute inset-0 bg-black opacity-40"></div>
                    <div className="absolute text-white text-center">
                        <h1 className="font-primary text-5xl font-bold mb-4">Giving the <span className="italic">best</span> <br />just for you</h1>
                    </div>
                </div>
                {/* <div className='bg-umber w-full pb-5'>
                    <h1 className="font-primary mx-auto text-white text-center text-4xl tracking-wide font-bold pt-16 mb-5">Testimonials</h1>
                    <div className="grid grid-flow-row lg:grid-flow-col max-w-screen-2xl mx-auto justify-stretch text-umber px-16 lg:px-28 py-14 space-y-10 lg:space-x-4 lg:space-y-0">
                        {testimonies.map((testimony, index) => (
                            <div key={index} className="relative pt-[1.5rem]">
                                <div className='w-full text-center absolute'>
                                    <CircleOutlinedIcon className='icon' />
                                </div>
                                <div className='card'>
                                    <p className='font-bold w-full text-center mt-8'>{testimony.name}</p>
                                    <p className='mt-5 mb-5 text-sm w-full'>{testimony.text}</p>
                                </div>
                                <div className='m-0 w-full text-center top-0 absolute'>
                                    <CircleRoundedIcon className='text-white' sx={{ fontSize: 80 }}  />
                                </div>
                                <div className='w-full text-center top-6 absolute'>
                                    <PersonIcon className='text-3xl text-holisticGreen' />
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}

                {/* Amenities */}
                <div className='mt-28 max-w-screen-2xl mx-auto'>
                    <div className='flex flex-col justify-center items-center'>
                        <h1 className="w-full text-center font-primary text-4xl text-earthyGreen">Facilities</h1>
                        <img src={earthyGreenFadedLine} className='w-96 mb-16' alt='decorative line' />
                    </div>
                    <FacilitiesCarousel />
                </div>
            </div>
        </div>
    )
}
