
  import {
    postQrAPICallwithFile,
    // getQrWithParamsAPICall,
  } from "./axiosMethodCall";
  
  //POST API -- append a new data to the db
  export const createInquiry = async (info) => {
    try {
      var formData = new FormData();
      formData.append("first_name", info.first_name);
      formData.append("last_name", info.last_name);
      formData.append("email", info.email);
      formData.append("phone", info.phone);
      formData.append("message", info.message);
   
      const response = await postQrAPICallwithFile(
        process.env.REACT_APP_LINK_QR  + "inquiries/create",
        formData
      );
  
      return { data: response.data };
    } catch (error) {
      return { error: error.response };
    }
  };
  
  //POST API -- append a new data to the db
  export const createFeedback = async (info) => {
    try {
      var formData = new FormData();
      formData.append("email", info.email);
      formData.append("feedback", info.feedback);
   
      const response = await postQrAPICallwithFile(
        process.env.REACT_APP_LINK_QR  + "feedbacks/create",
        formData
      );
  
      return { data: response.data };
    } catch (error) {
      return { error: error.response };
    }
  };
  