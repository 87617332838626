// import React, { useState, useRef } from 'react';

//components
import Carousel from '../../Components/Carousel/Carousel'

const WellnessPackages = () => {
  return (
    <div className="w-screen">
      <div className='bg-mutedGreen pt-32'>
        <div className='flex flex-col mb-20  max-w-screen-2xl mx-auto'>
          <h1 className=" font-secondary text-white font-bold text-3xl tracking-widest pl-9 lg:pl-28">Wellness Packages</h1>
          {/* TO DO Filter icon here */}
          <Carousel />
        </div>
      </div>
    </div>
  );
};

export default WellnessPackages;