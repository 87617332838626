import React from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import SquareIcon from '@mui/icons-material/Square';
import earthyGreenFadedLine from '../../Assets/Images/earthy-green-faded-line.png'

const WellnessPackagesItem = ({ display, data }) => {
    const selectedCard = data.find(item => item.key === display);

    if (!selectedCard) {
        return <p></p>;
    }

    const consultation = String("Consultation with OHA's Family Med Specialist")
    const cbc = String("CBC")
    const urinalysis = String("Urinalysis")
    const chestXray = String("Chest X-Ray")
    const stiTest = String("STI Tests (HIV Ag/Ab, Syphilis, Chlamydia")


    const servicesData = {
        kidsWellness: [consultation, cbc, urinalysis, "Stool Exam"],
        youngAdult: [consultation, cbc, chestXray, urinalysis, "STD Panel", stiTest],
        womensPrime: [consultation, cbc, chestXray, urinalysis, "Mammogram", "Pap Smear", stiTest],
        optimalMan: [consultation, cbc, chestXray, urinalysis, "Prostate Exam", "ECG", stiTest],
        workLifeBalance: [consultation, cbc, chestXray, urinalysis, "Stress Test", "Body Composition Analysis", "Mental Health Psychiatric Sessions Package"],
        preEmployment: [cbc, "Stool Analysis", chestXray, "Complete Physical"],
        lifeStartsAt40: [consultation, cbc, chestXray, urinalysis, "STD Panel", "Treadmill Stress Test", "Total Whole Body Densitometry", "Cancer Markers (AFP, PSA, CEA, CA19-9)", "Thyroid Panel C (FT3, FT4, TSH)", "Liver Test (Anti HCV, Anti-H Be, HB2Ag)"]
    };

    const completePhysical = ["weight", "height", "eyesight", "ear", "mouth", "nose", "blood pressure"]

    const services = servicesData[selectedCard.key] || [];

    return (
        <div className=' bg-calmGray lg:p-24 flex justify-center w-full flex-col text-earthyGreen lg:flex-row'>
            <img src={selectedCard.img} className='p-5 object-cover w-full h-[30rem] mt-3 lg:w-[35rem] lg:p-0' alt={selectedCard.alt} />
            <div className='w-full px-5 lg:pl-8 lg:mt-0'>
                <div className='flex mb-4'>
                    <p>Home</p>
                    <ArrowForwardIosIcon className="text-earthyGreen mx-1 mt-2" style={{ fontSize: '10px' }} />
                    <p className='font-bold'>Wellness Packages</p>
                </div>
                <p className='font-bold font-primary text-3xl'>{selectedCard.title}</p>
                <p className='font-bold font-primary text-3xl'>{selectedCard.subTitle}</p>
                <img src={earthyGreenFadedLine} alt='faded-line' className='mb-4 w-80'/>
                <p className='text-sm w-full lg:w-80'>{selectedCard.text}</p>

                <div className='flex-col space-y-1 mt-10 mb-16 lg:mb-5'>
                    {services.map((service, index) => (
                        <div key={index} className='tracking-wide'>
                            <CheckBoxOutlineBlankOutlinedIcon style={{ fontSize: '15px' }} /> {service}
                        </div>
                    ))}
                    {display === "preEmployment" && (
                        <div className='ml-5 flex flex-col'>
                            {completePhysical.map((item, itemIndex) => (
                                <div key={itemIndex}>
                                    <SquareIcon style={{ fontSize: '15px' }} /> {item}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default WellnessPackagesItem;
