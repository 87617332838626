import './home.css'
import ImageDisplay from '../../Assets/Images/dracaena-tree-sunlight.jpg'

import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { createInquiry } from '../../API/ApiCalls';
import 'react-toastify/dist/ReactToastify.css';

//icons
import PersonIcon from '@mui/icons-material/Person';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PhoneAndroidRoundedIcon from '@mui/icons-material/PhoneAndroidRounded';
import { HashLoader } from 'react-spinners';

export default function Contact() {
    const [contactDetails, setContactDetails] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleContactChange = (e) => {
        const { name, value } = e.target;
        setContactDetails((prevDetails) => ({
          ...prevDetails,
          [name]: value
        }));
    };
      
    async function handleSubmit() {
        setIsLoading(true)
        const response = await createInquiry(contactDetails);
        if(response.data){
            toast.success(response.data.message);
            setContactDetails({
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                message: '',
            })
        } else {
           response.error.data.messages.error_messages?.map((test) => (
            toast.error(test)
           ))
        }
        setIsLoading(false)
    }
    return (
        <div className="h-full relative w-screen mx-auto mb-20">
            <div>
                <div className="py-52">
                    <div className=" bg-earthyGreen h-64"></div>
                </div>
                <div className='h-full w-full p-14 mt-10 md:py-20 md:px-36 absolute top-0'>
                    <div className='flex max-w-screen-lg mx-auto w-full'>
                        <div className='w-0 lg:w-full'>
                            <img src={ImageDisplay} alt="" className='w-full h-full object-cover' />
                        </div>
                        <div className='w-full h-full bg-white p-10 flex flex-col justify-center sm:mx-auto border'>
                            <h2 className="text-start text-earthyGreen mb-2 text-2xl font-bold">Contact Us</h2>
                            <div className="mt-3">
                                <form className="space-y-6">
                                    <div className='flex-col flex gap-4'>
                                        <div className='flex-col flex gap-4 lg:flex-row'>
                                            <div className="focus:border-holisticGreen relative border-gray-300 rounded-md border w-full">
                                                <input
                                                    id="firstName"
                                                    placeholder='First Name'
                                                    name="first_name"
                                                    type="text"
                                                    value={contactDetails.first_name}
                                                    required
                                                    className="input"
                                                    autoComplete="off"
                                                    onChange={(e) => handleContactChange(e)} />
                                                <div className='absolute top-1.5 left-2'>
                                                    <PersonIcon className='text-holisticGreen' />
                                                </div>
                                            </div>

                                            <div className="focus:border-holisticGreen border-gray-300 rounded-md border relative w-full">
                                                <input id="lastname"
                                                    placeholder='Last Name'
                                                    name="last_name"
                                                    value={contactDetails.last_name}
                                                    type="text"
                                                    required
                                                    autoComplete="off"
                                                    className="input"
                                                    onChange={(e) => handleContactChange(e)} />
                                                <div className='absolute top-1.5 left-2'>
                                                    <PersonIcon className='text-holisticGreen' />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="focus:border-holisticGreen border-gray-300 rounded-md border relative w-full">
                                            <input
                                                id="email"
                                                placeholder='Email'
                                                name="email"
                                                value={contactDetails.email}
                                                type="email"
                                                autoComplete="off"
                                                required
                                                className="input"
                                                onChange={(e) => handleContactChange(e)} />
                                            <div className='absolute top-1.5 left-2'>
                                                <EmailRoundedIcon className='text-holisticGreen' />
                                            </div>
                                        </div>

                                        <div className="focus:border-holisticGreen border-gray-300 rounded-md border relative w-full">
                                            <input
                                                id="phone"
                                                placeholder='Phone'
                                                name="phone"
                                                value={contactDetails.phone}
                                                type="text"
                                                autoComplete="off"
                                                required
                                                className="input"
                                                onChange={(e) => handleContactChange(e)} 
                                                onKeyPress={(event) => {
                                                    if (!/[\d-]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                            <div className='absolute top-1.5 left-2'>
                                                <PhoneAndroidRoundedIcon className='text-holisticGreen' />
                                            </div>
                                        </div>

                                        <div className="focus:border-holisticGreen border-gray-300 rounded-md border relative w-full h-[6.2rem]">
                                            <textarea
                                                id="message"
                                                placeholder='Message'
                                                name="message"
                                                value={contactDetails.message}
                                                autoComplete="off"
                                                required
                                                className="p-2 ring-white rounded-md border-0 px-5 text-gray-900 w-full h-full placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-holisticGreen"
                                                onChange={(e) => handleContactChange(e)} />
                                        </div>
                                    </div>

                                    <div className="w-full flex justify-center">
                                        {
                                            isLoading ? (
                                                <button
                                                    type="button"
                                                    disabled
                                                    className="flex w-48 justify-center rounded-md bg-holisticGreen px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-holisticGreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-holisticGreen">
                                                        <HashLoader
                                                            color="white"
                                                            size={20}
                                                        />
                                                </button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    onClick={handleSubmit}
                                                    className="flex w-48 justify-center rounded-md bg-holisticGreen px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-holisticGreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-holisticGreen">
                                                        Send
                                                </button>
                                            )
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-center'>
                    <label className="text-start text-earthyGreen text-xl">Address: 2nd floor Banilad Town Center, Banilad, Cebu City, 6000 Philippines.</label>
                    <br/>
                    <label className="text-start text-earthyGreen text-xl">Contact number: (032) 344 5371</label>
                    <br/>
                    <label className="text-start text-earthyGreen mb-5 text-xl">E-mail address: info@onehealthaccess.com</label>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
